<template>
  <div class="app-box">
    <x-screen-tab :bindYear="selectObject.year" :bindMonth="selectObject.month" @change="screenChange"></x-screen-tab>
    <x-scroll-view :top="0.5">
      <table v-if="xhrData&&xhrData.length>0" class="data-table double-table">
        <colgroup>
          <col width="25%">
          <col width="25%">
          <col width="25%">
          <col width="25%">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>业务员</span>
            </th>
            <th>
              <span>月活跃度</span>
            </th>
            <th>
              <span>年活跃度</span>
            </th>
            <th>
              <span>百度毛利</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData" :key="index" @click="rowEvent(item.staffId);">
            <th scope="row">{{item.staffName}}</th>
            <td>{{item.monthAct}}</td>
            <td>{{item.yearAct}}</td>
            <td>{{item.grossProfit}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData&&xhrData.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import screenTab from "@/components/screenTab";
import noDataBg from "@/components/noDataBg";
import { cachePage } from "@/script/mixins/cachePage";
import { statisticsActivity } from "@/api/reportCenter";

export default {
  name: "activeIndex",
  data () {
    return {
      xhrData: null,
      ajaxEnd: false,
      selectObject: {
        type: 0,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      }
    };
  },
  mixins: [cachePage],
  created () {
    document.title = "活跃度统计";
    this.getDataList();
  },
  methods: {
    getDataList () {
      statisticsActivity(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getDataList();
    },
    rowEvent (staffId) {
      this.$router.push({
        path: `/reportCenter/active/twoLevel/${this.selectObject.year}/${this.selectObject.month || 0}/${staffId}`
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "活跃度统计";
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
</style>
